/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://kf26pszc3fafrepyoeozmcotda.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-yp66waby3vehzif737ectl74v4",
    "aws_cognito_identity_pool_id": "eu-central-1:3f06931f-ff94-47fa-bfd6-6d7c87343d0c",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_dUacmcN0A",
    "aws_user_pools_web_client_id": "38e2sd42kvrpo280csksdecnim",
    "oauth": {},
    "aws_user_files_s3_bucket": "castory-users-images211329-dev",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
